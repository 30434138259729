<template>
  <div class="text-center row">
    <br><br><br>
    <div class="col-12 col-lg-6 c text-center">
      <div class="card card-body">
        <h2 class="text-success">
          <i class="fa fa-check"></i>
          تم تثبيت اداة التحضير الذكي بنجاح! 
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>